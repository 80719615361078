﻿export const Role = {
    Admin: "Admin",
    Parent: "Parent",
    Student: "Student"
};

export const OssAssociatedRefType = {
    Parent: "Parent",
    Student: "Student"
};

export const ValidationMode = {
    None: "none",
    RegistrationAndAssessment: "regandassess",
    AssessmentOnly: "assessonly"
};

export const DayLetter = {
    Monday: "M",
    Tuesday: "T",
    Wednesday: "W",
    Thursday: "H",
    Friday: "F",
    Saturday: "S",
    Sunday: "N"
};